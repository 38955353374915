<template>
  <div class="LiteratureSeleList_container">
    <img class="drop_top_left" :src="require('@/assets/images/drop.png')" />
    <img class="drop_top_top" :src="require('@/assets/images/drop.png')" />
    <img class="drop_top_right" :src="require('@/assets/images/drop.png')" />
    <img class="drop_top_bottom" :src="require('@/assets/images/drop.png')" />
    <div class="vertical_right"></div>
    <div class="vertical_left"></div>
    <div class="LiteratureSeleList_content">
      <div class="LiteratureSeleList_title_block">
        <div class="LiteratureSeleList_title_number LiteratureSeleList_key">
          <span> 序号 </span>
          <div class="LiteratureSeleList_title_desc"></div>
        </div>
        <div class="LiteratureSeleList_title_document LiteratureSeleList_key">
          <span>文献</span>
          <div class="LiteratureSeleList_title_desc">
            点击文献标题了解内容详情
          </div>
        </div>
        <div class="LiteratureSeleList_title_people LiteratureSeleList_key">
          <span> 已选人数 </span>
          <div class="LiteratureSeleList_title_desc">
            每篇文献选择上限为{{ imitsNumber }}人
          </div>
        </div>
      </div>
      <div class="LiteratureSeleList_content_scroll_block" ref="pageScroll">
        <div class="LiteratureSeleList_content_scroll">
          <div
            class="LiteratureSeleList_content_item"
            v-for="(item, index) in dataList"
            :key="index"
          >
            <div
              class="literatureLimitItem_container"
              :style="getTopColor(index)"
              @click="goToDetail(item.id)"
            >
              <div class="literatureLimitItem_title_number">
                {{ index + 1 }}
              </div>
              <div
                class="literatureLimitItem_title_document literatureLimitItem_key"
              >
                {{ item.class_content }}
              </div>
              <div class="literatureLimitItem_title_people">
                {{ item.new_courseware_number }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="LiteratureSeleList_groupBtn">
        <el-button size="mini" @click="back">返回</el-button>
      </div> -->
    </div>
  </div>
</template>
<script>
// import { ElButton } from "element-plus";
import BSScroll from "better-scroll";

export default {
  name: "LiteratureSeleList",
  data() {
    return {
      dataList: [],
      doctorInfo: {},
      imitsNumber: 0,
    };
  },
  components: {
    // ElButton,
  },

  created() {
    this.doctorInfo = this.$store.state.common.doctorInfo;
    this.initData();

    document.getElementsByTagName("title")[0].innerHTML = "挑战赛文献选择专区";
  },
  methods: {
    getTopColor(index) {
      if (index == 0) return `color:#aa2264`;
      if (index % 2 !== 0) return "background:#f7ecef";
    },
    back() {
      this.$router.go(-1);
    },
    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BSScroll(this.$refs.pageScroll, {
            click: true,
            mouseWheel: true,
          });
        } else {
          this.scroll.refresh();
        }
      });
    },
    initData() {
      let url = this.$tools.getURL(this.$urls.article.articleList, {
        project_id: this.$route.query.project_id,
        period: this.$route.query.period,
        doctor_id: this.doctorInfo.id || "",
      });
      this.$axios.get(url).then((res) => {
        this.dataList = res.data;
        this.imitsNumber = this.dataList[0]?.courseware_number; //人数上限
      });
      setTimeout(() => {
        this.initScroll();
      }, 1000);
    },
    goToDetail(id) {
      this.$router.push({
        path: "/literatureLimitDetail",
        query: {
          id: id,
          imitsNumber: this.imitsNumber,
          isList: 1,
        },
      });
    },
    doCommit(item) {
      console.log(`item===>`, item);
      const iHelpHim = "iHelpHim"; // 我帮专家选
      if (item.new_courseware_number == this.imitsNumber) {
        this.$tips.warning({ text: "该文献已选完" });
        return;
      }
      if (item.is_choose) {
        this.$tips.warning({ text: "已选择该文献" });
      } else if (this.$route.query.t === iHelpHim) {
        const is_choose_item = this.dataList.find((item) => item.is_choose);
        if (is_choose_item) {
          this.$tips.warning({ text: "已选择该文献" });
          return;
        }
        let coursewareInfo = this.$tools.getStorage(
          "coursewareInfo",
          sessionStorage
        );
        if (coursewareInfo?.courseware_id) {
          this.$tips.warning({ text: "该文献已选完" });
          return;
        }
        item.is_choose = 1;
        item.new_courseware_number++;
        const data = {
          courseware_id: item.id,
          the_right_courseware_id: item.courseware_id,
          courseware_title: item.class_content,
          new_courseware_number: `${item.id}_${item.new_courseware_number}`,
        };
        this.$tools.setStorage("coursewareInfo", data, sessionStorage);
        this.$tips.success({ text: "选择成功" });
      } else {
        let url = this.$urls.article.chooseArticle;
        let data = {
          article_id: item.id,
          meet_id: item.id,
          project_id: item.project_id,
          period: item.period,
          doctor_id: this.doctorInfo.id,
          course_number: item.course_number,
        };
        this.$axios
          .post(url, data)
          .then((res) => {
            if (res.errcode) return;
            item.new_courseware_number++;
            item.is_choose = 1;
            this.$tips.success({ text: "选择成功" });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
.LiteratureSeleList_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e6e6e6;
  padding: 4vw;
}

.LiteratureSeleList_content {
  flex: 1 0 0;
  width: 100%;
  color: #999;
  font-size: 5vw;
  padding: 4vw 2vw;
  background: #fff;
  overflow: hidden;
  border-radius: 4vw;
  display: flex;
  flex-direction: column;
}

.LiteratureSeleList_title_block {
  width: 100%;
  height: 20vw;
  display: flex;
  font-weight: bold;
  align-items: center;
  color: #aa2264;
  border-bottom: 1px #ececec solid;
}
.LiteratureSeleList_key {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.LiteratureSeleList_title_number {
  width: 12vw;
}
.LiteratureSeleList_title_document {
  flex: 1 0 0;
  overflow: hidden;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
}
.LiteratureSeleList_title_people {
  width: 25vw;
}
.LiteratureSeleList_title_desc {
  height: 26px;
  color: #c6c6c6;
  font-size: 2vw;
  margin-top: 1vw;
  text-align: center;
}
.LiteratureSeleList_content_scroll_block {
  flex: 1 0 0;
  width: 100%;
  color: #999;
  font-size: 5vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.LiteratureSeleList_content_scroll {
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.LiteratureSeleList_content_item {
  width: 100%;
}
.LiteratureSeleList_container ::-webkit-scrollbar {
  display: none;
}

.LiteratureSeleList_container .vertical_right {
  height: 92%;
  width: 1x;
  border-left: 1px solid #ececec;
  position: fixed;
  top: 8vw;
  right: 32vw;
}
.LiteratureSeleList_container .vertical_left {
  height: 92%;
  width: 1px;
  border-left: 1px solid #ececec;
  position: fixed;
  top: 8vw;
  left: 18.5vw;
}
.LiteratureSeleList_container img {
  width: 1.5vw;
  height: 1.5vw;
}
.LiteratureSeleList_container .drop_top_left {
  position: fixed;
  top: 6vw;
  left: 17.9vw;
  z-index: 9;
}
.LiteratureSeleList_container .drop_top_top {
  position: fixed;
  top: 6vw;
  right: 31.4vw;
  z-index: 9;
}
.LiteratureSeleList_container .drop_top_right {
  position: fixed;
  bottom: 6vw;
  left: 17.9vw;
  z-index: 9;
}
.LiteratureSeleList_container .drop_top_bottom {
  position: fixed;
  bottom: 6vw;
  right: 31.4vw;
  z-index: 9;
}
.LiteratureSeleList_groupBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2vw;
}
.LiteratureSeleList_groupBtn .el-button--mini {
  background: #9b0047;
  border-radius: 20px !important;
  padding: 6px 6px;
  color: #fff;
  width: 90px;
  font-size: 4vw;
}

.LiteratureSeleList_container .literatureLimitItem_container {
  width: 100%;
  height: 24vw;
  display: flex;
  font-size: 4vw;
  align-items: center;
  justify-content: center;
  color: #777777;
  padding: 2vw 0;
}

.LiteratureSeleList_container .literatureLimitItem_key {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.LiteratureSeleList_container .literatureLimitItem_title_number {
  width: 10vw;
  font-weight: bold;
  /* border-right: 1px #ececec solid; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.LiteratureSeleList_container .literatureLimitItem_title_document {
  flex: 1 0 0;
  word-break: break-all;
  padding: 0 4vw;
}
.LiteratureSeleList_container .literatureLimitItem_title_people {
  /* border-left: 1px #ececec solid; */
  width: 25vw;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.LiteratureSeleList_container .literatureLimitItem_active {
  background: #f7ecef;
}
.LiteratureSeleList_container .literatureLimitItem_container .el-button--mini {
  border-radius: 20px !important;
  padding: 6px 6px;
  color: #fff;
  width: 90px;
  font-size: 2vw;
}
</style>